import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { MenuContext } from "../contexts/MenuContext";
import { useAuth } from "../providers/AuthProvider";
import AboutUs from "../screens/AboutUs";
import { PERMISSIONS } from "../store/constants";
import DetailedPreview from "../screens/service_bills/DetailedPreview";
import UserManagement from "../screens/UserManagement";
import ProductManagement from "../screens/ProductManagement";
import {
  InvoicePreviewFromPOS,
  InvoicePreviewOrderManagement,
} from "../screens/invoice";
import CollectedOrders from "../screens/CollectedOrders";
import ProductOffers from "../screens/ProductOffers";
import ReturnManagement from "../screens/ReturnManagement";

const DashboardLayout = lazy(() => import("../layouts/DashboardLayout"));
const Loader = lazy(() => import("../utils/Loader"));
const Dashboard = lazy(() => import("../screens/Dashboard"));
const CityManagement = lazy(() => import("../screens/CityManagement"));
const BannerManagement = lazy(() => import("../screens/BannerManagement"));
const CountryManagement = lazy(() => import("../screens/CountryManagement"));
const CouponManagment = lazy(() => import("../screens/CouponManagment"));

const FAQManagement = lazy(() => import("../screens/FAQManagement"));
const OrderManagement = lazy(() => import("../screens/OrderManagement"));
const InventoryManagement = lazy(() =>
  import("../screens/InventoryManagement")
);
const POSComposition = lazy(() => import("../screens/pos_layout/PosTable"));
const POSTaxable = lazy(() => import("../screens/pos_layout/PosTableWithTax"));

const PointofSale = lazy(() => import("../screens/PointofSale"));
const PrivacyPolicy = lazy(() => import("../screens/PrivacyPolicy"));
const ProfileManagement = lazy(() => import("../screens/ProfileManagement"));
const PurchaseMangement = lazy(() => import("../screens/PurchaseMangement"));
const ReviewRating = lazy(() => import("../screens/ReviewRating"));
const RoleManagement = lazy(() => import("../screens/RoleManagement"));
const ServiceManagement = lazy(() => import("../screens/ServiceManagement"));
const SliderManagement = lazy(() => import("../screens/SliderManagement"));
const StateManagement = lazy(() => import("../screens/StateManagement"));
const SubscriptionManagement = lazy(() =>
  import("../screens/SubscriptionManagement")
);
const Terms = lazy(() => import("../screens/Terms"));
const TransactionManagement = lazy(() =>
  import("../screens/TransactionManagement")
);
const Invoice = lazy(() => import("../screens/invoice/Invoice"));
const AdminRegister = lazy(() => import("../screens/login/AdminRegister"));
const ForgotPassword = lazy(() => import("../screens/login/ForgotPassword"));
const Login = lazy(() => import("../screens/login/Login"));
const OtpVerification = lazy(() => import("../screens/login/OtpVerification"));
const ResetPassword = lazy(() => import("../screens/login/ResetPassword"));
const ViewPurchase = lazy(() => import("../screens/purchase/ViewPurchase"));
const ServiceBill = lazy(() => import("../screens/ServiceBills"));
const TaskManagement = lazy(() => import("../screens/TaskManagement"));
const AddTask = lazy(() => import("../screens/subscription/AddTask"));
const AddCollectedOrder = lazy(() =>
  import("../screens/collectedOrder/AddCollectedOrder")
);

const CategoryManagement = lazy(() => import("../screens/CategoryManagement"));
const NewsletterAndInquireyManagment = lazy(() =>
  import("../screens/NewsletterAndInquireyManagment")
);

function PrivateRoute({ children, hasAccess, ...rest }) {
  let auth = useAuth();

  if (hasAccess) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.isAuthenticated() ? (
            <MenuContext>
              <DashboardLayout>
                <Suspense fallback={<Loader />}>{children} </Suspense>
              </DashboardLayout>
            </MenuContext>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.isAuthenticated() ? (
            <MenuContext>
              <DashboardLayout>Access Denied</DashboardLayout>
            </MenuContext>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

function RouteList() {
  const data = useSelector((state) => state.general);
  const {
    userData: { permissions, businessDetails },
  } = data;

  const hasAccess = (module_name) => {
    if (permissions?.includes(module_name)) {
      return true;
    } else return false;
    // let activePages = [];
    // activePages = JSON.parse(localStorage.getItem("permissions"));

    // if (!_.isNull(activePages) && activePages.includes(module_name)) {
    //   return true;
    // } else return false;
  };

  return (
    <Switch>
      <PrivateRoute hasAccess={hasAccess(PERMISSIONS.DASHBOARD)} exact path="/">
        <Dashboard />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/categories"
        hasAccess={hasAccess(PERMISSIONS.CATEGORY_MANAGEMENT)}
      >
        <CategoryManagement />
      </PrivateRoute>

      <PrivateRoute
        path="/newsletter-inquirey"
        hasAccess={hasAccess(PERMISSIONS.NEWSLETTER_INQUIREY)}
      >
        <NewsletterAndInquireyManagment />
      </PrivateRoute>
      <PrivateRoute
        path="/product/offers"
        hasAccess={hasAccess(PERMISSIONS.ORDER_MANAGEMENT)}
      >
        <ProductOffers />
      </PrivateRoute>
      <PrivateRoute
        path="/products"
        hasAccess={hasAccess(PERMISSIONS.PRODUCT_MANAGEMENT)}
      >
        <ProductManagement />
      </PrivateRoute>

      <PrivateRoute
        path="/reviewRating"
        hasAccess={hasAccess(PERMISSIONS.REVIEW_RATING)}
      >
        <ReviewRating />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/company/about"
        hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
      >
        <AboutUs />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/company/privacy-policy"
        hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
      >
        <PrivacyPolicy />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/company/terms"
        hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
      >
        <Terms />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/roles"
        hasAccess={hasAccess(PERMISSIONS.ROLE_ACCESS)}
      >
        <RoleManagement />
      </PrivateRoute>

      <PrivateRoute
        path="/tasks"
        hasAccess={hasAccess(PERMISSIONS.TASK_MANAGEMENT)}
      >
        <TaskManagement />
      </PrivateRoute>

      <PrivateRoute
        path="/purchase"
        hasAccess={hasAccess(PERMISSIONS.PURCHASE_MANAGEMENT)}
      >
        <PurchaseMangement />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/point-of-sale/add"
        hasAccess={hasAccess(PERMISSIONS.POINT_OF_SALE)}
      >
        {/* {businessDetails?.business_configurations?.tax_scheme == "regular" ? ( */}
        <POSTaxable />
        {/* ) : (
          <POSComposition />
        )} */}
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/point-of-sale"
        hasAccess={hasAccess(PERMISSIONS.POINT_OF_SALE)}
      >
        <PointofSale />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/point-of-sale/:order_id"
        hasAccess={hasAccess(PERMISSIONS.POINT_OF_SALE)}
      >
        <InvoicePreviewFromPOS />
      </PrivateRoute>

      <PrivateRoute
        path="/services"
        hasAccess={hasAccess(PERMISSIONS.SERVICE_MANAGEMENT)}
      >
        <ServiceManagement />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/service-bills/:customer_id/view"
        hasAccess={hasAccess(PERMISSIONS.SERVICE_BILLS)}
      >
        <DetailedPreview />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/service-bills/:group_by"
        hasAccess={hasAccess(PERMISSIONS.SERVICE_BILLS)}
      >
        <ServiceBill />
      </PrivateRoute>

      <PrivateRoute
        path="/view"
        hasAccess={hasAccess(PERMISSIONS.PURCHASE_MANAGEMENT)}
      >
        <ViewPurchase />
      </PrivateRoute>

      <PrivateRoute
        path="/banners"
        hasAccess={hasAccess(PERMISSIONS.BANNER_MANAGEMENT)}
      >
        <BannerManagement />
      </PrivateRoute>

      <PrivateRoute
        path="/sliders"
        hasAccess={hasAccess(PERMISSIONS.SLIDER_MANAGEMENT)}
      >
        <SliderManagement />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/orders"
        hasAccess={hasAccess(PERMISSIONS.ORDER_MANAGEMENT)}
      >
        <OrderManagement />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/inventory"
        hasAccess={hasAccess(PERMISSIONS.ORDER_MANAGEMENT)}
      >
        <InventoryManagement />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/collected-orders"
        hasAccess={hasAccess(PERMISSIONS.POINT_OF_SALE)}
      >
        <CollectedOrders />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/returns"
        hasAccess={hasAccess(PERMISSIONS.POINT_OF_SALE)}
      >
        <ReturnManagement />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/collected-orders/add-order"
        hasAccess={hasAccess(PERMISSIONS.POINT_OF_SALE)}
      >
        <AddCollectedOrder />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/orders/:order_id"
        hasAccess={hasAccess(PERMISSIONS.ORDER_MANAGEMENT)}
      >
        <InvoicePreviewOrderManagement />
      </PrivateRoute>

      <PrivateRoute
        path="/transactions"
        hasAccess={hasAccess(PERMISSIONS.TRANSACTION_MANAGEMENT)}
      >
        <TransactionManagement />
      </PrivateRoute>
      <PrivateRoute
        path="/orders/invoice"
        hasAccess={hasAccess(PERMISSIONS.ORDER_MANAGEMENT)}
      >
        <Invoice />
      </PrivateRoute>
      <PrivateRoute path="/subscriptions">
        <SubscriptionManagement />
      </PrivateRoute>
      <PrivateRoute path="/profile/:tab" hasAccess={true}>
        <ProfileManagement />
      </PrivateRoute>
      <PrivateRoute
        path="/company/faq"
        hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
      >
        <FAQManagement />
      </PrivateRoute>
      <PrivateRoute
        path="/users/:user_type"
        hasAccess={hasAccess(PERMISSIONS.USER_MANAGEMENT)}
      >
        <UserManagement />
      </PrivateRoute>
      <PrivateRoute
        path="/coupon"
        hasAccess={hasAccess(PERMISSIONS.COUPON_MANAGEMENT)}
      >
        <CouponManagment />
      </PrivateRoute>
      <PrivateRoute
        path="/setting/city"
        hasAccess={hasAccess(PERMISSIONS.SETTINGS)}
      >
        <CityManagement />
      </PrivateRoute>
      <PrivateRoute
        path="/setting/state"
        hasAccess={hasAccess(PERMISSIONS.SETTINGS)}
      >
        <StateManagement />
      </PrivateRoute>
      <PrivateRoute
        path="/setting/country"
        hasAccess={hasAccess(PERMISSIONS.SETTINGS)}
      >
        <CountryManagement />
      </PrivateRoute>

      <Route exact path="/register" component={AdminRegister} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/otp-verification" component={OtpVerification} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/chat" component={ResetPassword} />
    </Switch>
  );
}

export default RouteList;
